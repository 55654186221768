<template>
  <main-layout>
    <h1>{{ $t("newsletterStatistics") }}</h1>
    <!--    <div class="d-flex justify-content-start">-->
    <!--      <div-->
    <!--          class="transactionsPage__language"-->
    <!--          :class="{'transactionsPage__language-active': corporate}"-->
    <!--          @click="corporate = true ;wellness= false"-->
    <!--      >-->
    <!--        Corporate-->
    <!--      </div>-->
    <!--      <div-->
    <!--          class="transactionsPage__language"-->
    <!--          :class="{'transactionsPage__language-active': wellness}"-->
    <!--          @click="wellness = true ;corporate= false"-->
    <!--      >-->
    <!--        Wellness-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row newslettersPage__tableTitle">
      <div class="col-5">{{ $t("Name/Template") }}</div>
      <div class="col-1">{{ $t("Sent") }}</div>
      <div class="col-1">{{ $t("Delivered") }}</div>
      <div class="col-1">{{ $t("Viewed") }}</div>
      <div class="col-2">{{ $t("UniqueViews") }}</div>
      <div class="col-2">{{ $t("SentDate") }}</div>
    </div>
    <div>
      <div v-for="newsletter in newsletters">
        <newsletter-table-item :data="newsletter" />
      </div>
    </div>
    <div
      v-if="newsletters && newsletters.length === viewLimit"
      class="w-max-content m-auto"
    >
      <base-button @click="loadMore()" :load="true"
        >{{ $t("loadMore") }}
      </base-button>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import NewsletterTableItem from "@/components/elements/newslatter/NewsletterTableItem";
import { mapGetters } from "vuex";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";

export default {
  name: "NewslettersPage",
  components: { BaseButton, NewsletterTableItem, MainLayout },
  data() {
    return {
      corporate: true,
      wellness: false,
      viewLimit: 10,
    };
  },
  mounted() {
    if (!this.newsletters) this.getNewsletters();
  },
  methods: {
    loadMore() {
      this.viewLimit += 10;
      this.getNewsletters();
    },
    getNewsletters() {
      this.$http
        .get("email/campaigns", {
          params: {
            limit: this.viewLimit,
          },
        })
        .then(({ data }) => {
          this.$store.commit("SET_NEWSLETTERS", data.body);
        });
    },
  },
  computed: {
    ...mapGetters({
      newsletters: "NEWSLETTERS",
    }),
  },
};
</script>

<style></style>
