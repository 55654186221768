<template>
  <div class="row newsletterTableItem">
    <div class="col-5 newsletterTableItem__text">
      <div class="newsletterTableItem__text-bold">
        {{ data.name }}
      </div>
      <!--      <div class="newsletterTableItem__text-opacity">-->
      <!--        Provided article + learn more-->
      <!--      </div>-->
    </div>
    <div class="col-1 newsletterTableItem__text">
      {{ data.sent }}
    </div>
    <div class="col-1 newsletterTableItem__text">
      {{ data.delivered }}
    </div>
    <div class="col-1 newsletterTableItem__text">
      {{ data.viewed }}
    </div>
    <div class="col-2 newsletterTableItem__text">
      {{ data.uniqueViews }}
    </div>
    <div class="col-2 newsletterTableItem__text">
      <div class="newsletterTableItem__text-data">
        <data-formatted date-format="HH:mm" :data="data.sentDate" />
      </div>
      <div>
        <data-formatted date-format="dd MMM yyyy" :data="data.sentDate" />
      </div>
    </div>
  </div>
</template>

<script>
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  components: { DataFormatted },

  props: {
    data: Object,
  },
  name: "NewsletterTableItem",
};
</script>

<style></style>
